import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useAuth0, hasPermission } from '../../../auth0'

import { Dropdown, message } from 'antd'
import logo from '../../assets/images/loop-blue-logo.png'

const LogoWithAppSelector = ({ className }) => {
  const { getTokenSilently, permissions } = useAuth0()

  useEffect(() => {
    getTokenSilently().then(accessToken => localStorage.setItem('accessToken', accessToken))
  }, [getTokenSilently])

  const onClick = ({ item, key }) => {
    sessionStorage.setItem('tds-active-app', key)

    message.info(`Switched to ${item.props.name}`)
    window.location.reload()
  }

  const items = [
    hasPermission(permissions, ['tds_access_mode:admin']) && {
      key: 'admin',
      label: 'Transactional Data System',
      name: 'Transactional Data System',
    },
    hasPermission(permissions, ['tds_access_mode:checkin']) && {
      key: 'checkin',
      label: 'Warehouse Check-in System',
      name: 'Warehouse Check-in System',
    },
    hasPermission(permissions, ['tds_access_mode:sorting_tools']) && {
      key: 'sorting_tools',
      label: 'Sorting Tool System',
      name: 'Sorting Tool System',
    },
  ].filter(Boolean)

  return (
    <div
      className={`logoWrapper ${className || ''}`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
      }}
    >
      <Dropdown
        disabled={false}
        menu={{ items, onClick }}
        placement="bottom"
      >
        <img
          alt="Loop logo"
          src={logo}
          style={{
            cursor: 'pointer',
            height: '45px',
          }}
        />
      </Dropdown>
    </div>
  )
}

LogoWithAppSelector.propTypes = {
  className: PropTypes.string,
}

export default LogoWithAppSelector

