import React, { useState } from 'react'
import { useAsync } from 'react-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import {
  Button,
  Col,
  Empty,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd'
import compact from 'lodash.compact'
import flatten from 'lodash.flatten'
import isEmpty from 'lodash.isempty'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash.uniqby'
import {
  createShippingContainer,
  deleteShippingContainer,
  getPartners,
  getStores,
  getShippingContainers,
  setShippingContainerStatusToAllocated,
  setShippingContainerStatusToUnknown,
  updateShippingContainer,
} from './api'
import { Can, ErrorMessage, Icon } from '../../../_shared/components'
import { routePropTypes } from '../../../_shared/types'
import { getTableHeaderCheckboxFilter, getTableHeaderDatePicker, getTableHeaderSearchInput, localizedDate } from '../../utils'
import { ShippingContainerModal } from './ShippingContainerModal'
import { StatusModal } from './StatusModal'
import ShippingContainerUploader from './ShippingContainerUploader'
import csvSample from '../../assets/templates/import_shipping_containers.csv'

const convertToFlatArray = (variable) => compact(flatten([variable]))

const ShippingContainers = () => {
  const { Paragraph, Title } = Typography
  const location = useLocation()
  const navigate = useNavigate()

  const [isShippingContainerModalVisible, setShippingContainerModalVisibility] = useState(false)
  const [isStatusModalVisible, setStatusModalVisibility] = useState(false)
  const [isModalLoading, setModalLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedShippingContainer, setSelectedShippingContainer] = useState(null)
  const queryParams = queryString.parse(location.search)
  const itemsPerPage = parseInt(queryParams.items_per_page, 10) || 25
  const page = parseInt(queryParams.page, 10) || 1
  const statusFilter = convertToFlatArray(queryParams.status)
  const conditionFilter = convertToFlatArray(queryParams.condition)
  const arrivedAtFilter = convertToFlatArray(queryParams.arrivedAt)
  const dataProcessingFilter = convertToFlatArray(queryParams.dataProcessing)
  const depositAllocationFilter = convertToFlatArray(queryParams.depositAllocation)
  const verificationFilter = convertToFlatArray(queryParams.verification)
  const partnerFilter = convertToFlatArray(queryParams.partner)
  const countryFilter = convertToFlatArray(queryParams.country)
  const storeFilter = convertToFlatArray(queryParams.store_id)

  const { data, error, isLoading, reload } = useAsync({
    arrivedAtFilter,
    conditionFilter,
    countryFilter,
    dataProcessingFilter,
    depositAllocationFilter,
    identityCode: queryParams.identity_code,
    itemsPerPage,
    page,
    partnerFilter,
    promiseFn: getShippingContainers,
    statusFilter,
    storeFilter,
    verificationFilter,
    watch: location.search,
  })

  const partners = useAsync({
    promiseFn: getPartners,
  })

  const stores = useAsync({
    promiseFn: getStores,
  })

  const countries = uniqBy(partners.data, 'country').map(country => country.country).sort()

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />

  const handleDeleteShippingContainer = (recordId) => {
    deleteShippingContainer({ recordId })
      .then(() => handleSuccess({ description: 'Shipping container is removed successfully.' }, undefined, true))
      .catch((error) => handleFailure(error, { description: 'Shipping container removal is failed.' }))
  }

  const handleSuccess = (messageOptions = {}, id = undefined, isDelete = false) => {
    setShippingContainerModalVisibility(false)
    setStatusModalVisibility(false)
    setErrorMessage(null)

    if (id) {
      navigate(`/shipping-containers/${id}`)
    } else if (isDelete) {
      navigate('/shipping-containers?page=1')
    } else {
      reload()
    }

    notification.open({
      description: 'Shipping container is added successfully',
      duration: 3,
      icon: <Icon color="#52c41a" type="checkCircle" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Shipping container creation is failed.',
      duration: 3,
      icon: <Icon color="#f5222d" type="closeCircle" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'CREATE':
      return createShippingContainer({ fields: payload })
        .then((data) => handleSuccess({}, data.id))
        .catch(handleFailure)

    case 'UPDATE':
      return updateShippingContainer({ fields: payload })
        .then(() => handleSuccess({ description: 'Shipping container is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Shipping container update is failed.' }))

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleStatusSubmit = (statusValue, recordId) => {
    switch (statusValue) {
    case 'manually_allocated':
      return setShippingContainerStatusToAllocated({ recordId })
        .then(() => handleSuccess({ description: 'Shipping container status is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Shipping container status update is failed.' }))

    case 'manually_unknown':
      return setShippingContainerStatusToUnknown({ recordId })
        .then(() => handleSuccess({ description: 'Shipping container status is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Shipping container status update is failed.' }))

    default:
      throw Error('Unhandled status type')
    }
  }

  const handleOpenModal = ({ selectedShippingContainer, setModalVisibility }) => {
    setSelectedShippingContainer(selectedShippingContainer)
    setErrorMessage(null)
    setModalVisibility(true)
    setModalLoading(false)
  }

  const handleTableChange = (pagination, filters) => {
    const queryStringObject = {
      ...filters,
      items_per_page: pagination.pageSize,
      page: pagination.current,
    }

    navigate({
      ...location,
      search: queryString.stringify(queryStringObject),
    })
  }

  const handleDownload = () => {
    setTimeout(() =>
      notification.open({
        description: 'Sample downloaded successfully.',
        duration: 3,
        icon: <Icon color="#52c41a" type="checkCircle" />,
        message: 'Success',
      }),
    3000)
  }

  const shippingContainers = (data && data.shipping_containers) || []
  const totalItems = (data && data.pagination_meta.total_items) || 0
  const disabledStatuses = ['sent_back', 'processed']

  const isDisabled = (shippingContainerStatus, shippingContainerDataProcessing) => (
    disabledStatuses.includes(shippingContainerStatus) ||
    (shippingContainerDataProcessing === 'canceled')
  )

  const partnerFilters = partners.data
    ? orderBy(partners.data.map(({ code, country }) => ({ country, text: code, value: code })), 'text', 'asc')
    : []

  const countryFilters = countries?.map(country => ({ text: country, value: country }))
    ?? []

  const storeFilters = stores.data ? orderBy(stores.data.stores.map(({ country, id, name }) => ({ country, text: name, value: id })), 'text', 'asc')
    : []

  const tableColumns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
    },
    {
      className: 'identityCodeSelenium',
      dataIndex: 'identity_code',
      filteredValue: isEmpty(queryParams.identity_code) ? null : queryParams.identity_code,
      key: 'identity_code',
      render: (_, record) => (
        <Paragraph copyable={{ text: record.identity_code }}>
          <Link name="identityCodeLinkSelenium" to={`/shipping-containers/${record.id}`}>
            {record.identity_code}
          </Link>
        </Paragraph>
      ),
      title: 'Identity code',
      ...getTableHeaderSearchInput('identityCodeSearchSelenium'),
    },
    {
      className: 'sealCodeSelenium',
      dataIndex: 'seal_code',
      key: 'sealCode',
      title: 'Seal code',
    },
    {
      className: 'partnerCodeSelenium',
      dataIndex: 'partner_code',
      filteredValue: isEmpty(partnerFilter) ? null : partnerFilter,
      key: 'partner',
      title: 'Partner',
      ...getTableHeaderCheckboxFilter('partnerCodeFilterSelenium', partnerFilters),
    },
    {
      className: 'countrySelenium',
      dataIndex: 'country',
      filteredValue: isEmpty(countryFilter) ? null : countryFilter,
      key: 'country',
      title: 'Country',
      ...getTableHeaderCheckboxFilter('countryCodeFilterSelenium', countryFilters),
    },
    {
      className: 'storeSelenium',
      dataIndex: ['store', 'name'],
      filteredValue: isEmpty(storeFilter) ? null : storeFilter,
      key: 'store_id',
      title: 'Store Name',
      ...getTableHeaderCheckboxFilter('storeFilterSelenium', storeFilters),
    },
    {
      className: 'dataProcessingSelenium',
      dataIndex: 'data_processing',
      filteredValue: isEmpty(dataProcessingFilter) ? null : dataProcessingFilter,
      key: 'dataProcessing',
      title: 'Data processing',
      ...getTableHeaderCheckboxFilter('dataProcessingFilterSelenium', [
        {
          text: 'processed data',
          value: 'processed_data',
        },
        {
          text: 'raw data',
          value: 'raw_data',
        },
        {
          text: 'canceled',
          value: 'canceled',
        },
      ]),
    },
    {
      className: 'depositAllocationSelenium',
      dataIndex: 'deposit_allocation',
      filteredValue: isEmpty(depositAllocationFilter) ? null : depositAllocationFilter,
      key: 'depositAllocation',
      title: 'Deposit allocation',
      ...getTableHeaderCheckboxFilter('depositAllocationFilterSelenium', [
        {
          text: 'allocated',
          value: 'allocated',
        },
        {
          text: 'unallocated',
          value: 'unallocated',
        },
        {
          text: 'unknown',
          value: 'unknown',
        },
      ]),
    },
    {
      className: 'verificationSelenium',
      dataIndex: 'verification',
      filteredValue: isEmpty(verificationFilter) ? null : verificationFilter,
      key: 'verification',
      title: 'Verification',
      ...getTableHeaderCheckboxFilter('verificationFilterSelenium', [
        {
          text: 'unknown',
          value: 'unknown',
        },
        {
          text: 'verified by CoMS',
          value: 'verified_by_coms',
        },
      ]),
    },
    {
      className: 'statusSelenium',
      dataIndex: 'status',
      filteredValue: isEmpty(statusFilter) ? null : statusFilter,
      key: 'status',
      title: 'Status',
      ...getTableHeaderCheckboxFilter('statusFilterSelenium', [
        {
          text: 'arrived',
          value: 'arrived',
        },
        {
          text: 'processed',
          value: 'processed',
        },
        {
          text: 'sent back',
          value: 'sent_back',
        },
      ]),
    },
    {
      className: 'arrivedAtSelenium',
      dataIndex: 'arrived_at',
      filteredValue: isEmpty(arrivedAtFilter) ? null : arrivedAtFilter,
      key: 'arrivedAt',
      render: dateTime => <span>{localizedDate(dateTime)}</span>,
      title: 'Arrived at',
      ...getTableHeaderDatePicker('arrivedAtDatePickerSelenium', 'arrived_at_gteq', 'arrived_at_lteq'),
    },
    {
      className: 'conditionSelenium',
      dataIndex: 'condition',
      filteredValue: isEmpty(conditionFilter) ? null : conditionFilter,
      key: 'condition',
      title: 'Condition',
      ...getTableHeaderCheckboxFilter('conditionFilterSelenium', [
        {
          text: 'ok',
          value: 'ok',
        },
        {
          text: 'seal damaged',
          value: 'seal_damaged',
        },
        {
          text: 'shipping container damaged',
          value: 'shipping_container_damaged',
        },
        {
          text: 'shipping container and seal damaged',
          value: 'shipping_container_and_seal_damaged',
        },
        {
          text: 'unprocessable',
          value: 'unprocessable',
        },
        {
          text: 'incomplete',
          value: 'incomplete',
        },
      ]),
    },
    {
      className: 'quantityOfPackagesSelenium',
      dataIndex: 'quantity_of_packages',
      key: 'quantityOfPackages',
      title: 'Quantity of packages',
    },
    {
      className: 'actionSelenium',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <span className="action-list">
          {record.deposit_allocation === 'unallocated' &&
            <Can
              requiredPermission="update_deposit_allocation:shipping_containers"
              yes={() => (
                <Button
                  className="action-list-button"
                  name="changeStatusSelenium"
                  onClick={() => handleOpenModal({
                    selectedShippingContainer: record,
                    setModalVisibility: setStatusModalVisibility,
                  })}
                  size="small"
                >
                  Change status
                </Button>
              )}
            />
          }

          <Can
            requiredPermission="update:shipping_containers"
            yes={() => (
              <Button
                className="action-list-button"
                disabled={isDisabled(record.status, record.data_processing)}
                name="updateShippingContainerSelenium"
                onClick={() => handleOpenModal({
                  selectedShippingContainer: record,
                  setModalVisibility: setShippingContainerModalVisibility,
                })}
                size="small"
              >
                Update
              </Button>
            )}
          />
          <Can
            requiredPermission="delete:shipping_containers"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="action-list-button"
                disabled={record.quantity_of_packages > 0 || isDisabled(record.status, record.data_processing)}
                okText="Yes"
                onConfirm={() => handleDeleteShippingContainer(record.id)}
                title="Are you sure you want to delete this shipping container?"
              >
                <Button
                  danger
                  disabled={record.quantity_of_packages > 0 || isDisabled(record.status, record.data_processing)}
                  ghost
                  name="deleteShippingContainerSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
    },
  ]

  return (
    <div>
      <Title>Shipping containers</Title>
      <Row justify="space-between" type="flex">
        <Col span={12} style={{ marginBottom: '20px', textAlign: 'left' }}>
          <Row type="flex">
            <Can
              requiredPermission="create:shipping_containers"
              yes={() => <ShippingContainerUploader />}
            />

            <a
              className="download-link"
              download
              href={csvSample}
              onClick={handleDownload}
              style={{ height: '32px', lineHeight: '32px', marginLeft: '20px' }}
            >
              Download Sample&nbsp;
              <Icon type="download" />
            </a>
          </Row>
        </Col>
        <Col span={12} style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:shipping_containers"
            yes={() => (
              <Button
                name="addNewShippingContainerSelenium"
                onClick={() => handleOpenModal({
                  selectedShippingContainer: null,
                  setModalVisibility: setShippingContainerModalVisibility,
                })}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>

        <Col span={24}>
          <Table
            bordered
            className="shippingContainerTableSelenium"
            columns={tableColumns}
            dataSource={shippingContainers}
            loading={isLoading}
            locale={{ emptyText: <Empty description="No shipping containers" /> }}
            onChange={handleTableChange}
            pagination={{
              current: page,
              pageSize: itemsPerPage,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              total: totalItems,
            }}
            rowKey="id"
            tableLayout="auto"
          />
        </Col>
      </Row>

      {isShippingContainerModalVisible && (
        <ShippingContainerModal
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          partners={partnerFilters}
          selectedShippingContainer={selectedShippingContainer}
          setModalLoading={setModalLoading}
          setVisibility={setShippingContainerModalVisibility}
          stores={storeFilters}
        />
      )}

      {isStatusModalVisible && (
        <StatusModal
          errorMessage={errorMessage}
          isModalLoading={isModalLoading}
          onSubmit={handleStatusSubmit}
          selectedShippingContainerId={selectedShippingContainer.id}
          setModalLoading={setModalLoading}
          setVisibility={setStatusModalVisibility}
        />
      )}
    </div>
  )
}

ShippingContainers.propTypes = {
  ...routePropTypes,
}

export default ShippingContainers
