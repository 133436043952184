import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { Button, Flex, Popconfirm } from 'antd'

import noImgPackage from '../../../_shared/assets/images/no-image-pack.jpeg'

import { currentSortingDetails } from '../../Layout'

const MergedItemsHeader = ({ items, selectPackage }) => {
  const handleAllMergedKeySorted = () => {
    currentSortingDetails.value.barCodeAction.value = ''

    items.forEach(({ upc, quantity }) => {
      currentSortingDetails.value.sortedQuantities.value[upc] = quantity
      localStorage.setItem('sorted_barcode_qty', JSON.stringify(currentSortingDetails.value.sortedQuantities.value))

      selectPackage({})
    })
  }

  return (
    <>
      <Flex align="center" className="merged-title" justify="space-between">
        <div>
          <img
            alt="logo"
            onError={(e) => {
              e.currentTarget.src = noImgPackage
            }}
            src={items[0].product.sorting_key?.media[0]?.mini_url || noImgPackage}
            style={{ maxWidth: '40px', minWidth: '40px' }}
          />

        </div>
        <div><b>{items[0].product.sorting_key.title}</b></div>
      </Flex>

      <div className="merged-button">
        <Popconfirm
          cancelText={<Trans>sorting_tools:cancel</Trans>}
          description={<Trans>sorting_tools:confirmSortAllItem</Trans>}
          okText={<Trans>sorting_tools:yes</Trans>}
          onConfirm={handleAllMergedKeySorted}
        >
          <Button
            className="sort-button"
            color="green"
            size="large"
          >
            <Trans>sorting_tools:allSorted</Trans>
          </Button>
        </Popconfirm>
      </div>
    </>
  )
}

MergedItemsHeader.propTypes = {
  items: PropTypes.array.isRequired,
  selectPackage: PropTypes.func.isRequired,
}

export default MergedItemsHeader
