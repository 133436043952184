import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty } from 'lodash'
import { Button, Checkbox, DatePicker, Input } from 'antd'
import { CalendarOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

export const useCustomSearchParams = (defaultParams) => {
  const [search, setSearch] = useSearchParams()
  useEffect(() => {
    if (isEmpty(search.toString()) && defaultParams)
      setSearch(defaultParams)
  }, [defaultParams, search, setSearch])

  const searchAsObject = queryString.parse(search.toString())

  return [searchAsObject, setSearch]
}

export const DateFilter = ({ endParam, filter, startParam }) => {
  const [search, setSearch] = useCustomSearchParams()
  const [startInDatePicker] = useState(dayjs(search[startParam]))
  const [endInDatePicker] = useState(search[endParam] ? dayjs(search[endParam]) : dayjs())

  const handleReset = () => {
    const tempParams = { ...search }
    delete tempParams[startParam]
    delete tempParams[endParam]

    setSearch({
      ...tempParams,
      modal_page: search.modal_page ? 1 : null,
      page: 1 ,
    })

    filter.clearFilters()
    filter.confirm()
  }

  const handleSearch = () => {
    const endDate = `${dayjs(filter.selectedKeys[1]).format('YYYY-MM-DD')} 23:59:59`
    const startDate = `${filter.selectedKeys[0]} 00:00:00`

    setSearch({
      ...search, [endParam]: endDate,
      modal_page: search.modal_page ? 1 : null,
      page: 1,
      [startParam]: startDate,
    })

    filter.confirm()
  }

  return (
    <div style={{ padding: 8 }}>
      <RangePicker
        allowClear={true}
        defaultValue={[startInDatePicker, endInDatePicker]}
        onChange={(_, dateString) => filter.setSelectedKeys(dateString)}
        placeholder={[search[startParam] ?? 'Start date', search[endParam] ? dayjs(search[endParam]).format('YYYY-MM-DD') : 'End date']}
        style={{ display: 'flex', marginBottom: 8, width: 300 }}
        value={[filter.selectedKeys[0] ? dayjs(filter.selectedKeys[0]) : undefined, filter.selectedKeys[1] ? dayjs(filter.selectedKeys[1]) : undefined]}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          className="ant-btn ant-btn-link ant-btn-sm"
          name="datePickerButtonResetSelenium"
          onClick={handleReset}
          size="small"
        >
          Reset
        </span>
        <Button
          data-testid="datePickerButtonSearchSelenium"
          disabled={filter.selectedKeys.length === 0}
          name="datePickerButtonSearchSelenium"
          onClick={handleSearch}
          size="small"
          style={{ marginLeft: '20px' }}
          type="primary"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

DateFilter.propTypes = {
  endParam: PropTypes.string,
  filter: PropTypes.object,
  startParam: PropTypes.string,
}

export const getTableHeaderDatePicker = (name, startParam, endParam) => {
  return {
    filterDropdown: (filter) => (
      <DateFilter endParam={endParam} filter={filter} startParam={startParam} />
    ),
    filterIcon: <CalendarOutlined name={name} />,
  }
}

export const getTableHeaderSearchInput = (name) => {
  const handleReset = (filter) => {
    filter.clearFilters()
    filter.confirm()
  }

  return {
    filterDropdown: (filter) => (
      <div className={name} name="customFilterWrapper" style={{ padding: 8 }}>
        <Input
          onChange={e => filter.setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => filter.confirm({ closeDropdown: true })}
          placeholder="Search"
          style={{ display: 'block', marginBottom: 8, width: 188 }}
          value={filter.selectedKeys}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            className="ant-btn ant-btn-link ant-btn-sm"
            disabled={!filter.selectedKeys[0]}
            name="searchFilterResetButtonSelenium"
            onClick={() => handleReset(filter)}
            size="small"
          >
            Reset
          </span>
          <Button
            name="searchFilterButtonSelenium"
            onClick={() => filter.confirm({ closeDropdown: true })}
            size="small"
            style={{ marginLeft: '20px' }}
            type="primary"
          >
            Search
          </Button>
        </div>
      </div>
    ),
    filterIcon: <SearchOutlined className={`${name}_icon`} name={name} />,
  }
}

export const CheckboxFilter = ({filter, filters, name}) => {
  let initialFilters = {}
  let clearedFilters = {}

  filters.forEach(f => {
    initialFilters[f.value] = { ...f, selected: !!~filter.selectedKeys.indexOf(f.value.toString()) }
    clearedFilters[f.value] = { ...f, selected: false }
  })

  const [filterState, setFilterState] = useState(initialFilters)

  const handleReset = (filter) => {
    setFilterState({ ...clearedFilters })
    filter.clearFilters()
    filter.confirm()
  }

  const handleSelect = (filter, e) => {
    filterState[e.target.name].selected = !filterState[e.target.name].selected
    const tempKeys = Object.values(filterState).filter(elem => elem.selected).map(el => el.value)
    filter.setSelectedKeys(tempKeys)
  }

  return (
    <div name={`customFilterWrapper ant-table-filter-dropdown ${name}`}>
      <ul className={`${name}_searchFilter ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical`}>
        {(filters ?? []).map(option =>
          <li className="ant-dropdown-menu-item" key={option.value} role="menuitem">
            <Checkbox checked={filterState[option.value]?.selected} name={option.value.toString()} onChange={e => handleSelect(filter, e)}>{option.text}</Checkbox>
          </li>,
        )}
      </ul>
      <div className="ant-table-filter-dropdown-btns" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          className="ant-btn ant-btn-link ant-btn-sm"
          disabled={!filter.selectedKeys[0]}
          name="searchFilterResetButtonSelenium"
          onClick={() => handleReset(filter)}
          size="small"
        >
          Reset
        </span>
        <Button
          className={`${name}_searchFilterButtonSelenium`}
          name="searchFilterButtonSelenium"
          onClick={() => filter.confirm({ closeDropdown: true })}
          size="small"
          style={{ marginLeft: '20px' }}
          type="primary"
        >
          Search
        </Button>
      </div>
    </div>
  )
}

CheckboxFilter.propTypes = {
  filter: PropTypes.object,
  filters: PropTypes.array,
  name: PropTypes.string,
}

export const getTableHeaderCheckboxFilter = (name, filters) => {
  if (filters.length > 0)
    return {
      filterDropdown: (filter) => (
        <CheckboxFilter filter={filter} filters={filters} name={name} />
      ),
      filterIcon: <FilterOutlined className={`${name}_icon`} name={name} />,
    }
}

export default getTableHeaderDatePicker
