import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { last, groupBy, isEmpty, max, mergeWith, pickBy, pull, remove, sumBy } from 'lodash'
import { Button, Col, Divider, Drawer, message, Row, Popconfirm, Spin, Typography } from 'antd'
import { BinBadge, DrawerList, MergedItemsHeader, SortingItem, SortingQuantityModal } from '../../components'

import { getBin, setBinSortingStatusToClose } from '../../api'

import noImgPackage from '../../../_shared/assets/images/no-image-pack.jpeg'

import { currentSortingDetails } from '../../Layout'

const { Title } = Typography

const SortingPage = ({ barcodeList, ids }) => {
  let binResultBody = {}

  const [isSpinLoading, setIsSpinLoading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams('')
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [binStatus, setBinStatus] = useState(ids.map(id => ({ code: null, id, isLoading: true, packageNumber: 0 })))
  const [packages, setPackages] = useState([])

  const currentSearchParams = searchParams.getAll('sorting_ids') || []

  const spin = {
    isLoading: isSpinLoading,
    setIsLoading: setIsSpinLoading,
    setPercent,
  }

  useEffect(() => {
    if (selectedPackage || currentSortingDetails.value.modalVisiblity.notListedItem.value || isEmpty(currentSortingDetails.value.barCodeAction.value)) return

    if (!packages[currentSortingDetails.value.barCodeAction.value]) {
      currentSortingDetails.value.modalVisiblity.notListedItem.value = true
    }

  //eslint-disable-next-line
  }, [currentSortingDetails.value.barCodeAction.value])

  useEffect(() => {
    if (currentSortingDetails.value.closingInProgress.value) return

    Promise.all(
      ids.map((id, index) =>
        getBin(id)
          .then(data => {
            currentSortingDetails.value.fetchedBins.value[id] = data

            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: data.identity_code, id, isLoading: false, packageNumber: sumBy(data.sorting_event.packages, 'quantity')}

              return updatedArray
            })
          })
          .catch(error => {
            currentSortingDetails.value.fetchedBins.value[id] = null
            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: null, id, isLoading: false, packageNumber: 0 }

              return updatedArray
            })
          })
      ))
      .finally(() => {
        const mergedPackages = {}
        Object.keys(currentSortingDetails.value.fetchedBins.value).forEach((binId) => {

          const bin = currentSortingDetails.value.fetchedBins.value[binId]

          bin.sorting_event.packages.forEach((pkg) => {
            if (!barcodeList[pkg.upc])
              return null

            mergedPackages[pkg.upc] = {
              ...pkg,
              bin_id: binId,
              product: barcodeList[pkg.upc],
              quantity: pkg.quantity + (mergedPackages[pkg.upc] ? mergedPackages[pkg.upc].quantity : 0),
            }
          })
        })

        setPackages(mergedPackages)
        setIsLoading(false)
      })
  }, [ids, barcodeList])

  const resetApp = () => {
    currentSortingDetails.value.selectedBins.value = []
    currentSortingDetails.value.sortedQuantities.value = {}
    currentSortingDetails.value.fetchedBins.value = {}
    currentSortingDetails.value.noMoreItem.value = []
    currentSortingDetails.value.search.value = ''

    localStorage.removeItem('no_more_barcode')
    localStorage.removeItem('sorted_barcode_qty')

    setTimeout(() => {
      currentSortingDetails.value.closingInProgress.value = false
      spin.setIsLoading(false)
    }, 500)
  }

  const handleCloseBatch = () => {
    spin.setIsLoading(true)

    let remainingItems = Object.assign({}, currentSortingDetails.value.sortedQuantities.value)
    const bins = currentSortingDetails.value.fetchedBins.value
    const calculatedPercentageStep = 110 / bins.length

    binResultBody = {}

    Object.keys(bins).forEach(binId => {
      const binPackages = bins[binId].sorting_event.packages

      binResultBody[binId] = binResultBody[binId] || {}

      binPackages.forEach(pckg => {
        const hasScannedItem = (remainingItems[pckg.upc] && remainingItems[pckg.upc] > 0)
        const scanned_qty = (pckg.quantity < remainingItems[pckg.upc]) ? pckg.quantity : max([remainingItems[pckg.upc], 0])

        binResultBody[binId][pckg.upc] = {
          not_found_qty: pckg.quantity - scanned_qty,
          scanned_qty,
        }

        if (hasScannedItem)
          remainingItems[pckg.upc] = remainingItems[pckg.upc] - pckg.quantity
      })
    })

    const notListedItems = pickBy(remainingItems , (itemQty) => itemQty > 0)

    binResultBody[last(Object.keys(binResultBody))] = mergeWith(
      binResultBody[last(Object.keys(binResultBody))],
      notListedItems,
      (objValue = 0, srcValue = 0) => objValue + srcValue
    )
    currentSortingDetails.value.closingInProgress.value = true
    setBinsStatusForClose(0, calculatedPercentageStep)
  }

  const setBinsStatusForClose = (index, calculatedPercentageStep) => {
    const binId = Object.keys(currentSortingDetails.value.fetchedBins.value)[index]

    const result = {
      ...binResultBody[binId],
    }

    setBinSortingStatusToClose({ result, sorting_bin_id: binId })
      .then(() => {
        const newParams = pull(currentSearchParams, binId)
        setSearchParams({ sorting_ids: newParams })
      })
      .catch((error) => {
        console.warn(`Error id: #${binId}`)
        console.warn(error)
      })
      .finally(() => {
        const nextIndex = index + 1

        spin.setPercent((nextIndex) * calculatedPercentageStep)

        if (nextIndex < Object.keys(binResultBody).length) {
          setBinsStatusForClose(nextIndex, calculatedPercentageStep)
        } else {
          resetApp()
          message.success({
            content: <Trans>sorting_tools:done</Trans>,
          })
        }
      })
  }

  const handleRedo = (barcode) => {
    const sortedQuantities = { ...currentSortingDetails?.value?.sortedQuantities?.value }
    delete sortedQuantities[barcode]

    currentSortingDetails.value.sortedQuantities.value = sortedQuantities
    remove(currentSortingDetails.value.noMoreItem.value, (n) => n === barcode)

    localStorage.setItem('no_more_barcode', JSON.stringify(currentSortingDetails.value.noMoreItem.value))
    localStorage.setItem('sorted_barcode_qty', JSON.stringify(sortedQuantities))

    setPackages({ ...packages })
  }

  if (isLoading) return <Spin fullscreen name="loadingSpinnerSelenium" />

  const searchValue = currentSortingDetails.value.search.value.toLowerCase()

  const sortedItems = Object.entries(currentSortingDetails.value.sortedQuantities.value).map(([barcode, quantity]) => {
    const product = barcodeList[barcode] || {}

    return {
      barcode,
      image: product.media?.[0]?.small_url || noImgPackage,
      name: product.product_name || barcode,
      quantity,
    }
  })

  const filteredPackages = Object.keys(packages).filter(barcode => {
    const remainingItem = packages[barcode].quantity - (currentSortingDetails.value.sortedQuantities.value[barcode] || 0)

    return !((remainingItem <= 0) || currentSortingDetails.value.noMoreItem.value.includes(barcode))
  })

  const dataSource = filteredPackages.map(upc => ({ ...packages[upc], key: packages[upc].id }))
  const mergedData = groupBy(dataSource, 'product.sorting_key.title')

  return (
    <>
      <Row>
        <Button
          onClick={setOpen}
          type="primary"
        >
          <p>
            <Trans>sorting_tools:showSortedItems</Trans>
          </p>
        </Button>
      </Row>

      <Row>
        <BinBadge bins={binStatus} />
      </Row>

      <Divider style={{ borderColor: '#7cb305'}} variant="dotted">
        <Trans>sorting_tools:packages</Trans>
      </Divider>

      {filteredPackages.length === 0 && (
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Popconfirm
              cancelText={<Trans>sorting_tools:cancel</Trans>}
              description={<Trans>sorting_tools:areYouSureClose</Trans>}
              okText={<Trans>sorting_tools:ok</Trans>}
              onConfirm={handleCloseBatch}
              title={<Trans>sorting_tools:closeBatchPopup</Trans>}
            >
              <Button className="close-batch-btn" size="large">
                <Trans>sorting_tools:closeBatch</Trans>
              </Button>
            </Popconfirm>
          </Col>

          <Col offset={9} span={6}>
            <Title level={4} style={{ textAlign: 'center' }}>
              <Trans>sorting_tools:showSortedItems</Trans>
            </Title>

            <DrawerList data={sortedItems} handleRemove={handleRedo} />
          </Col>
        </Row>
      )}

      <Row className="item-columns" style={{ marginTop: 30, minHeight: '100vh' }}>
        {filteredPackages.length > 0 && Object.keys(mergedData).map(sortingKey => (
          <div className="merged-box" key={sortingKey}>
            {mergedData[sortingKey].length > 1 &&
              <MergedItemsHeader
                items={mergedData[sortingKey]}
                selectPackage={setSelectedPackage}
              />
            }

            {React.Children.toArray(mergedData[sortingKey].map(({ product, upc, quantity }, index) => (
              <SortingItem
                barcode={upc}
                image={product.media[0]?.small_url || noImgPackage}
                index={index}
                isHighlighted={product.product_name.toLowerCase().includes(searchValue) || upc.toLowerCase().includes(searchValue) || product.sorting_key.toLowerCase().includes(searchValue)}
                name={product.product_name}
                quantity={quantity}
                selectPackage={setSelectedPackage}
              />
            )))}
          </div>
        ))}

        <Drawer
          onClose={() => setOpen(false)}
          open={open}
          title={<Trans>sorting_tools:sortedItems</Trans>}
        >
          <DrawerList data={sortedItems} handleRemove={handleRedo} />
        </Drawer>
      </Row>

      {!isEmpty(selectedPackage) &&
        <SortingQuantityModal
          onClose={() => setSelectedPackage(null)}
          selectedPackage={selectedPackage}
        />
      }

      <Spin fullscreen percent={percent} spinning={isLoading} />
    </>
  )}

SortingPage.propTypes = {
  barcodeList: PropTypes.object,
  ids: PropTypes.array,
}

export default SortingPage
