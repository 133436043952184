import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Divider, Flex, Modal, InputNumber, Form, Popconfirm } from 'antd'

import { currentSortingDetails } from '../../Layout'

const SortingQuantityModal = ({ onClose, selectedPackage }) => {
  const qtyInputRef = useRef(null)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    qtyInputRef.current.focus()
  }, [])

  const handleSubmit = () => {
    const qty = form.getFieldValue('quantity')

    if (qty < 0) return

    setLoading(true)

    const { barcode } = selectedPackage

    currentSortingDetails.value.sortedQuantities.value[barcode] = (!!currentSortingDetails.value.sortedQuantities.value[barcode])
      ? currentSortingDetails.value.sortedQuantities.value[barcode] + form.getFieldValue('quantity')
      : qty

    localStorage.setItem('sorted_barcode_qty', JSON.stringify(currentSortingDetails.value.sortedQuantities.value))

    setLoading(false)

    currentSortingDetails.value.barCodeAction.value = ''

    onClose()
  }

  const handleRemainingItems = () => {
    setLoading(true)

    const { barcode } = selectedPackage

    currentSortingDetails.value.barCodeAction.value = ''

    currentSortingDetails.value.noMoreItem.value.push(barcode)
    currentSortingDetails.value.sortedQuantities.value[barcode] = currentSortingDetails.value.sortedQuantities.value[barcode] || 0

    localStorage.setItem('no_more_barcode', JSON.stringify(currentSortingDetails.value.noMoreItem.value))
    localStorage.setItem('sorted_barcode_qty', JSON.stringify(currentSortingDetails.value.sortedQuantities.value))

    setLoading(false)

    onClose()
  }

  return (
    <Modal
      cancelText={t('sorting_tools:cancel')}
      confirmLoading={isLoading}
      footer={({ props }) => ([
        <Popconfirm
          cancelText={<Trans>sorting_tools:cancel</Trans>}
          description={<Trans>sorting_tools:areYouSureNoMore</Trans>}
          key="noMoreKey"
          okText={<Trans>sorting_tools:yes</Trans>}
          onConfirm={handleRemainingItems}
          title={<Trans>sorting_tools:noMoreItem</Trans>}
        >
          <Button
            className="sort-no-more-button"
            disabled={isLoading}
            size="large"
            type="primary"
          >
            <Trans>sorting_tools:noMoreItemButton</Trans>
          </Button>
        </Popconfirm>,
        //eslint-disable-next-line
        ...props.children,
      ])}
      okButtonProps={{ className: 'sort-button' }}
      okText={t('sorting_tools:ok')}
      onCancel={onClose}
      onOk={handleSubmit}
      open={true}
      title={t('sorting_tools:enterQuantity')}
    >
      <Divider style={{ borderColor: '#7cb305' }} variant="dashed" />

      <Flex align="center" style={{ marginBottom: '20px' }}>
        <img alt={selectedPackage.name} src={selectedPackage.image} style={{ marginRight: '10px', maxWidth: '80px' }} />
        <h3>{selectedPackage.name}</h3>
      </Flex>

      <Form
        form={form}
        initialValues={{ quantity: 0 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="quantity"
          rules={[{ message: t('sorting_tools:quantityRequired'), required: true }]}
        >
          <InputNumber
            onFocus={(event) => event.target.select()}
            onPressEnter={handleSubmit}
            ref={qtyInputRef}
            size="large"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

SortingQuantityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedPackage: PropTypes.object.isRequired,
}

export default SortingQuantityModal
