import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAsync } from 'react-async'
import { useAuth0 } from '../auth0'
import { signal } from '@preact/signals-react'
import { isEmpty, unionBy } from 'lodash'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Layout, message, Spin } from 'antd'
import { Profile, LogoWithAppSelector } from '../_shared/components'
import { CountrySelector, Footer, ScannerAction } from './components/'
import {
  BinsPage,
  SortingPage,
} from './pages'

import { getPartners, getUserSortingBins } from './api.js'

const { Content, Header } = Layout

export const currentSortingDetails = signal({
  barCodeAction: signal(''),
  closingInProgress: signal(false),
  country: signal(localStorage.getItem('sorting_country') || null),
  fetchedBins: signal({}),
  modalVisiblity: {
    notListedItem: signal(false),
  },
  noMoreItem: signal(JSON.parse(localStorage.getItem('no_more_barcode') || '[]')),
  search: signal(''),
  selectedBins: signal([]),
  sortedQuantities: signal(JSON.parse(localStorage.getItem('sorted_barcode_qty') || '{}')),
})

const SortingToolLayout = ({ barcodes }) => {
  const { user } = useAuth0()
  const [isLoading , setIsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const partners = useAsync({
    promiseFn: getPartners,
  })

  useEffect(() => {
    getUserSortingBins({ userId: user.sub })
      .then(({ sorting_bins }) => {
        if (!isEmpty(sorting_bins)) {
          currentSortingDetails.value.country.value = sorting_bins[0].country
          localStorage.setItem('sorting_country', sorting_bins[0].country)

          setSearchParams({ sorting_ids: sorting_bins.map(bin => bin.id)})

          message.info({
            content: <Trans>sorting_tools:youHaveSortingInProgress</Trans>,
            key: 'sortingInProgress',
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [user.sub, setSearchParams])

  if (partners.isLoading || isLoading)
    return <Spin fullscreen name="loadingSpinnerSelenium" />

  const countryOptions = unionBy(partners?.data?.map(({ country }) => ({ label: country, value: country })), 'value')

  if (isEmpty(currentSortingDetails.value.country.value))
    currentSortingDetails.value.country.value = countryOptions[0].value

  const sortingInProgress = searchParams.getAll('sorting_ids').length > 0

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="check-in-header" style={{ background: '#fff', padding: '0 16px 0 0' }}>
        <LogoWithAppSelector className="check-in-logo" />
        <Profile
          className="check-in-profile"
          widget={<CountrySelector countries={countryOptions} isDisabled={sortingInProgress} />}
        />
      </Header>

      <Content style={{ margin: '16px' }}>
        <div style={{ background: '#fff', marginBottom: 90, minHeight: '100vh', padding: 24 }}>
          {sortingInProgress
            ? <SortingPage barcodeList={barcodes[currentSortingDetails.value.country.value]} ids={searchParams.getAll('sorting_ids')} />
            : <BinsPage />
          }
        </div>
      </Content>

      <Footer selectedBins={currentSortingDetails.value.selectedBins.value} />

      <ScannerAction />
    </Layout>
  )
}

SortingToolLayout.propTypes = {
  barcodes: PropTypes.object,
}

export default SortingToolLayout
