import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { omit } from 'lodash'
import Icon, {
  AppstoreOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DashboardOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FilterOutlined,
  InboxOutlined,
  LogoutOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SearchOutlined,
  UndoOutlined,
  UploadOutlined,
} from '@ant-design/icons'

const iconTypes = {
  appstore: AppstoreOutlined,
  checkCircle: CheckCircleOutlined,
  close: CloseOutlined,
  closeCircle: CloseCircleOutlined,
  dashboard: DashboardOutlined,
  download: DownloadOutlined,
  fileAdd: FileAddOutlined,
  filter: FilterOutlined,
  inbox: InboxOutlined,
  logOut: LogoutOutlined,
  search: SearchOutlined,
  shop: ShopOutlined,
  shopping: ShoppingCartOutlined,
  undo: UndoOutlined,
  upload: UploadOutlined,
}

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CustomIcon = React.forwardRef((props, ref) => {
  const cleanedProps = props.disabled ? omit(props, 'onClick') : props

  return <Icon component={iconTypes[props.type]} ref={ref} {...cleanedProps} />
})
const StyledIcon = styled(({ disabled = false, ...props }) => <CustomIcon disabled={disabled} {...props} />)`
  color: ${props => props.color || 'default'};
  cursor: ${({ cursor, disabled }) => disabled ? 'not-allowed' : cursor} !important;

  opacity: ${({ disabled }) => disabled ? .4 : 1};

  font-size: 14px;
  margin: 0 5px;

  &:hover {
    color: #0095FF;
  };

`

CustomIcon.displayName = 'Icon'

CustomIcon.propTypes = {
  color: PropTypes.string,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
}

export default StyledIcon
