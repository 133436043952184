import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { List } from 'antd'
import { Icon } from '../../../_shared/components'

const DrawerList = ({ data=[], handleRemove }) => (
  <List
    dataSource={data}
    itemLayout="horizontal"
    locale={{ emptyText: <Trans>sorting_tools:noData</Trans> }}
    renderItem={({ barcode, image, quantity, name }) => (
      <List.Item
        actions={[
          <div key="undo-action" onClick={() => handleRemove(barcode)}>
            <Icon color="#1677ff" type="undo" />
            <p>
              <Trans>sorting_tools:undo</Trans>
            </p>
          </div>,
        ]}
        className="sorted-items-list"
      >
        <List.Item.Meta
          avatar={<img alt="adg" src={image} style={{ width: '50px' }} />}
          description={<><Trans>sorting_tools:quantity</Trans>: {quantity}</>}
          title={name}
        />
      </List.Item>
    )}
  />
)

DrawerList.propTypes = {
  data: PropTypes.array,
  handleRemove: PropTypes.func,
}

export default DrawerList
